import {
    Box, Button, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

interface FilterComponentProps {
    status: string;
    setStatus: (status: string) => void;
    setWorkerId: (workerId: number) => void;
    setPage: (page: number) => void;
    page: number;
}

export const FilterComponent: React.FC<FilterComponentProps> = (
    {
        status,
        setStatus,
        setWorkerId,
        setPage,
        page,
    }) => {
    const handleWorkerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkerId(Number(event.target.value));
    };

    const handlePreviousPage = () => {
        if (page > 0) {
            const newPage = page - 1;
            setPage(newPage);
        }
    };

    const handleNextPage = () => {
        const newPage = page + 1;
        setPage(newPage);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', marginBottom: 2 }}>
            <TextField
                label="Worker Id"
                variant="outlined"
                onChange={handleWorkerIdChange}
                sx={{ marginRight: 2 }}
            />
            <FormControl variant="outlined" sx={{ marginRight: 2, minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                    label="Status"
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value);
                    }}
                >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Created">Created</MenuItem>
                    <MenuItem value="Failed">Failed</MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                </Select>
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 20 }}
                onClick={handlePreviousPage}
                disabled={page === 0}
            >
                Previous Page
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleNextPage}
            >
                Next Page
            </Button>
        </Box>
    );
};
