import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { WithdrawalRow } from 'interfaces/admin';
import { THS, RED } from './styles';

interface ConfirmModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    withdrawal: WithdrawalRow | null;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ open, onClose, onConfirm, withdrawal }) => {
    if (!withdrawal) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Typography variant="h6" component="h2">
                    Are you sure you want to set this withdrawal to status <span className={RED}>failed</span> ?
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography><span className={THS}>Withdrawal ID: </span>{withdrawal.withdrawalId}</Typography>
                    <Typography><span className={THS}>Worker ID: </span>{withdrawal.workerId}</Typography>
                    <Typography><span className={THS}>Status: </span>{withdrawal.withdrawalStatus}</Typography>
                    <Typography><span className={THS}>Full Name: </span>{withdrawal.firstName} {withdrawal.lastName}
                    </Typography>
                    <Typography><span className={THS}>Net Amount: </span>{withdrawal.netAmount}</Typography>
                    <Typography><span className={THS}>Gross Amount: </span>{withdrawal.grossAmount}</Typography>
                    <Typography><span className={THS}>Type: </span>{withdrawal.withdrawalType}</Typography>
                    <Typography><span className={THS}>Reference: </span>{withdrawal.reference}</Typography>
                    <Typography><span className={THS}>Period: </span>{withdrawal.period}</Typography>
                    <Typography><span className={THS}>Date: </span>{withdrawal.withdrawalDate}</Typography>
                </Box>
                <br/>
                <hr/>
                <br/>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="primary" onClick={onConfirm}>
                        Yes Proceed
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
