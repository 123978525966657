import { CompanyDto } from "model/Company";
import { CompanyGroup } from "model/CompanyGroup";
import { GroupCompany } from "model/GroupCompany";
import { WorkerDto } from "model/Worker";
import { API_ADMIN_ROOT, API_ROOT, ENVIRONMENT, PAXML_ROOT, } from "services/baseURLs.config";
import firebase from "services/firebase/firebase.config";
import { CompanyEmailData, TimeReportRow, UserData } from "interfaces/admin";
import { validateEmailSettings, validateUserData } from "utils/validator";

export const getCompanies = async (
    status: "unverified" | "verified" | "disabled",
    page: number = 0
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/Company/${status}?page=${page}&amount=40`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const companies = await result.json();
        return companies;
    } catch (error) {
        console.log("Something went wong");
        console.log(error);
    }
};
export const getGigs = async (page: number) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/Gigs?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const gigs = await result.json();
        return gigs;
    } catch (error) {
        console.log("Something went wong");
        console.log(error);
    }
};
export const getCompanyGroups = async () => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/CompanyGroup`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const companyGroups: CompanyGroup[] = await result.json();
        return companyGroups;
    } catch (error) {
    }
};

export const getCompanySetting = async (id: number | undefined) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/companies/${id}/fee`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }
        return await result.json();
    } catch (error: any) {
        throw new Error(error);
    }
}

const getCompanyValue = (company: CompanyDto | undefined, key: string | undefined) => {
    const setting = company?.companySettings?.find(setting => setting.key === key);
    return setting?.value;
}

export const updateCompanyFees = async (company: CompanyDto | undefined) => {
    const companyFeesKeys = [ 'MarketplaceFee', 'WorkpoolFee', 'StaffingFee' ];
    for (const key of companyFeesKeys) {
        const value = getCompanyValue(company, key);
        const valueType = getCompanyValue(company, `${key}Type`);

        const payload = {
            "key": key,
            "value": value,
            "valueType": valueType,
            "companyId": company?.id
        }

        if (payload.value !== undefined && payload.valueType !== undefined) {
            try {
                const token = await firebase.auth().currentUser?.getIdToken();
                const result = await fetch(`${API_ADMIN_ROOT}/companies/${company?.id}/fee`, {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                });
            } catch (error: any) {
                throw new Error(error);
            }
        }
    }
}

export const updateCompany = async (company: CompanyDto | undefined) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/Company/${company?.id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(company),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const updatedCompany = await result.json();
        return updatedCompany;
    } catch (error) {
    }
};

export const verifyCompany = async (companyId: number | undefined) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/Company/verify`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                companyId,
                verified: true,
            }),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const verified = await result.json();
        return verified;
    } catch (error) {
    }
};

export const createCompanyGroup = async (data: CompanyGroup | undefined) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/CompanyGroup`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const updateCompanyGroup = async (data: CompanyGroup | undefined) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/CompanyGroup/${data?.id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const deleteCompanyGroup = async (companyGroupId: number) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/CompanyGroup/${companyGroupId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getGroupCompanies = async (companyGroupId: number | undefined) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/CompanyGroup/${companyGroupId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const groupCompanies: GroupCompany[] = await result.json();
        return groupCompanies;
    } catch (error) {
    }
};

export const addCompanyToGroup = async (
    companyId: number,
    companyGroupId: number | undefined
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/CompanyGroup/${companyGroupId}/Add`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ companyId: companyId }),
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const deleteCompanyFromGroup = async (
    companyGroupId: number | undefined,
    companyId: number
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/CompanyGroup/${companyGroupId}/${companyId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getWorkers = async (page: number, firstName: string | null = null, lastName: string | null = null) => {
    let url = `${API_ADMIN_ROOT}/Worker?page=${page}&amount=100`;
    if (firstName) {
        url += `&firstName=${encodeURIComponent(firstName)}`;
    }
    if (lastName) {
        url += `&lastName=${encodeURIComponent(lastName)}`;
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return await result.json();
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getWorkerEndorsements = async (workerId: number | undefined) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/endorsement/${workerId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const endorsements = await result.json();
        return endorsements;
    } catch (error) {
    }
};
export const createWorkerEndorsement = async (
    workerId: number | undefined,
    skill: string | undefined,
    companyId: number | undefined | string,
    gigId: number | undefined | string
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/endorsement`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                workerId: workerId,
                skill: skill,
                companyId: companyId,
                gigId: gigId,
            }),
        });
        if (!result.ok) {
            return {
                success: false,
            };
        }
        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
        };
    }
};
export const deleteWorkerEndorsement = async (
    endorsementId: number | undefined
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/endorsement/${endorsementId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (!result.ok) {
            return {
                success: false,
            };
        }
        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
        };
    }
};
export const disableEnableWorker = async (
    id: number | undefined,
    status: boolean | undefined
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/Worker/Active/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                active: status,
            }),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error) {
    }
};
export const updateWorker = async (workerData: WorkerDto) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/Worker/${workerData.id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(workerData),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error) {
    }
};

export const notifyCompanyForGigapay = async (
    companyId: number | undefined
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/company/${companyId}/notify-for-gigapay`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        return await result.json();
    } catch (error) {
    }
};

export const enableSimflexityPayCompany = async (
    companyId: number | undefined
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ADMIN_ROOT}/company/SimflexityPay/${companyId}`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        return await result.json();
    } catch (error) {
    }
};

export const getStaffingPools = async () => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/StaffingPool`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const staffingPools = await result.json();
        return staffingPools;
    } catch (error) {
        console.log("Something went wong");
        console.log(error);
    }
};

export const getCompaniesInStaffingPool = async (poolId: number) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/StaffingPool/Company/${poolId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const staffingPools = await result.json();
        return staffingPools;
    } catch (error) {
        console.log("Something went wong");
        console.log(error);
    }
};

export const getWorkersInStaffingPool = async (poolId: number) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/StaffingPool/Worker/${poolId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const staffingPools = await result.json();
        return staffingPools;
    } catch (error) {
        console.log("Something went wong");
        console.log(error);
    }
};

export const createStaffingPool = async (name: string) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/StaffingPool/AddStaffingPool`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: name }),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const staffingPool = await result.json();
        return staffingPool;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const addCompanyToStaffingPool = async (
    companyId: number,
    poolId: number | undefined
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/StaffingPool/AddCompany`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ poolTypeId: poolId, companyId: companyId }),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const companies = await result.json();
        return companies;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const addWorkerToStaffingPool = async (
    workerId: number,
    poolId: number | undefined
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/StaffingPool/AddWorker`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ workerId: workerId, poolTypeId: poolId }),
        });
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const workers = await result.json();
        return workers;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const deleteCompanyFromStaffingPool = async (
    poolId: number,
    companyId: number
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ROOT}/StaffingPool/Company/${poolId}/${companyId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const deleteWorkerFromStaffingPool = async (
    poolId: number,
    workerId: number
) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(
            `${API_ROOT}/StaffingPool/Worker/${poolId}/${workerId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getPaxml = async (fromDate: number, toDate: number) => {
    try {
        const result = await fetch(
            `${PAXML_ROOT}?fromDate=${fromDate}&toDate=${toDate}&environment=${ENVIRONMENT}`,
            {
                method: "GET",
                headers: {
                    "x-functions-key": `5rul70bqnZ5Id4a3M8ZXE_3sEenITJvOGYDPI-U-AmY5AzFuRrCJyA==`,
                },
            }
        );
        if (!result.ok) {
            throw new Error(result.statusText);
        }
        const paxml = await result.json();
        return paxml;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getCompanyUsersById = async (companyId: any | undefined) => {
    if (companyId === undefined) {
        throw new Error('Company ID is required');
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/companies/${companyId}/users`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return await result.json();
    } catch (error: any) {
        throw new Error(error);
    }
};

export const deleteCompanyUser = async (userId: any | undefined, companyId: any | undefined) => {
    if (userId === undefined || companyId === undefined) {
        throw new Error('User & Company ID is required');
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/companies/${companyId}/users/${userId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        });

        if (!result.ok) {
            throw new Error(`There must be atleast one Owner for the company`);
        }

        return result.ok;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const createCompanyUser = async (companyId: any | undefined, user: UserData) => {
    const validate = validateUserData(user);

    if (!validate.isValid) {
        throw new Error(validate.errors.join(', '));
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/companies/${companyId}/users`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
        });

        if (result.ok) {
            return result.ok;
        }

        if (result.status === 409) {
            throw new Error(`Email address already in use for this Company`);
        } else {
            throw new Error(result.statusText);
        }
    } catch (error: any) {
        throw new Error(error);
    }
};

export const updateCompanyUser = async (companyId: any, user: UserData) => {
    const validate = validateUserData(user);
    if (!validate.isValid) {
        throw new Error(validate.errors.join(', '));
    }

    // update email settings
    const emailUpdateResult = await setEmailNotifications(user.id, companyId, user?.emailNotifications);
    if (!emailUpdateResult) {
        throw new Error('Failed to update email settings');
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/companies/${companyId}/users/${user.id}/role`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return result.ok;
    } catch (error: any) {
        throw new Error(error);
    }
}

/**
 * @param featureToggle, if set to true it will disable the stamp clock feature
 */
export const stampClockFeatureToggle = async (companyId: any, featureToggle: boolean = true) => {
    if (companyId === undefined) {
        throw new Error('Company ID is required');
    }
    const payload = {
        "companyId": companyId,
        "key": "LocalStampClock",
        "value": featureToggle
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/companies/${companyId}/settings`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return result.ok;
    } catch (error: any) {
        throw new Error(error);
    }
}

export const setEmailNotifications = async (userId: number, companyId: number, emailNotifications: any) => {
    const data: CompanyEmailData = {
        userId,
        companyId,
        emailSetting: emailNotifications
    }
    const validate = validateEmailSettings(data);
    if (!validate.isValid) {
        throw new Error(validate.errors.join(', '));
    }

    const payload = {
        "userIdToUpdate": userId,
        "emailNotifications": emailNotifications
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/companies/${companyId}/users/${userId}/mailNotifications`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return result.ok;
    } catch (error: any) {
        throw new Error(error);
    }
}

export const cancelTimeReport = async (timeReportId: number, companyId: number) => {
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const url = `${API_ADMIN_ROOT}/companies/${companyId}/timeReport/${timeReportId}/cancel`;

        const result = await fetch(url, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!result.ok) {
            const res = await result.json();
            throw new Error(res.errorMessage);
        }

        return result.ok;
    } catch (error: any) {
        throw new Error(error);
    }
}

const getCompanySearchCriteria = (companyIdOrName: string | number| null): number | string => {
    if (companyIdOrName && !isNaN(Number(companyIdOrName))) {
        return `&companyId=${companyIdOrName}`;
    }
    if (companyIdOrName) {
        return `&companyName=${companyIdOrName}`;
    }

    return '';
}

export const getTimeReports = async (
    page: number,
    amount: number,
    TimeReportStatus: string | null,
    sortAscending: boolean,
    companyIdentifier: string | number | null
): Promise<TimeReportRow[]> => {
    try {
        let companyIdOrName = getCompanySearchCriteria(companyIdentifier);
        sortAscending = !sortAscending;
        let queryParams = `page=${page}&amount=${amount}&sortAscending=${sortAscending}${companyIdOrName}`;
        if (TimeReportStatus !== 'All') {
            queryParams = `${queryParams}&status=${TimeReportStatus}`;
        }

        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ADMIN_ROOT}/timeReports?${queryParams}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return await result.json();
    } catch (error: any) {
        throw new Error(error);
    }
}

export const getCompanyProductType = async (companyId: number | undefined) => {
    if (companyId === undefined) {
        console.error("Get Company Product Type: Company ID is undefined");
        return "Unknown";
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/companies/${companyId}/membership`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return await result.json();
    } catch (error: any) {
        throw new Error(error);
    }
}

export const setCompanyProductType = async (companyId: number | undefined, membershipTypeName: string) => {
    if (companyId === undefined) {
        console.error("Set Company Product Type: Company ID is undefined");
        return false;
    }

    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/companies/${companyId}/membership`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ membershipTypeName })
        });
    } catch (error: any) {
        throw new Error(error);
    }
}

export const getWorkerWithdrawals = async (status: string, workerId: number | undefined, page: number) => {
    let queryParams = `?page=${page}`;
    if (status !== 'All') {
        queryParams += `&status=${status}`;
    }
    if (workerId) {
        queryParams += `&workerId=${workerId}`;
    }
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/workers/withdrawals${queryParams}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!result.ok) {
            throw new Error(result.statusText);
        }

        return await result.json();
    } catch (error: any) {
        throw new Error(error);
    }
};

export const setWorkerWithdrawalStatus = async (
    workerId: number | undefined,
    withdrawalId: number | undefined,
    status: string | undefined
) => {
    if (workerId === undefined || withdrawalId === undefined || status === undefined) {
        throw new Error('Worker ID, Withdrawal ID and Status are required');
    }
    const queryParams = `?status=${status}`;
    try {
        const token = await firebase.auth().currentUser?.getIdToken();
        const result = await fetch(`${API_ROOT}/admin/workers/${workerId}/withdrawals/${withdrawalId}${queryParams}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!result.ok) {
            const errorResponse = await result.json();
            throw new Error(`Error ${result.status}: ${errorResponse.message || result.statusText}`);
        }

        return await result.json();
    } catch (error: any) {
        throw new Error(`Failed to set worker withdrawal status: ${error.message}`);
    }
}

export const admin = {
    getCompanies,
    getCompanyGroups,
    getGroupCompanies,
    updateCompany,
    verifyCompany,
    createCompanyGroup,
    updateCompanyGroup,
    deleteCompanyGroup,
    addCompanyToGroup,
    deleteCompanyFromGroup,
    getWorkers,
    getWorkerEndorsements,
    createWorkerEndorsement,
    deleteWorkerEndorsement,
    updateWorker,
    disableEnableWorker,
    notifyCompanyForGigapay,
    getGigs,
    enableSimflexityPayCompany,
    getStaffingPools,
    getCompaniesInStaffingPool,
    getWorkersInStaffingPool,
    createStaffingPool,
    addCompanyToStaffingPool,
    addWorkerToStaffingPool,
    deleteCompanyFromStaffingPool,
    deleteWorkerFromStaffingPool,
    getPaxml,
    updateCompanyFees,
    getCompanySetting,
    getCompanyUsersById,
    deleteCompanyUser,
    createCompanyUser,
    updateCompanyUser,
    stampClockFeatureToggle,
    setEmailNotifications,
    cancelTimeReport,
    getTimeReports,
    getCompanyProductType,
    setCompanyProductType,
    getWorkerWithdrawals,
    setWorkerWithdrawalStatus,
};
